<template>
  <div class="header-view" ref="fenleis">
    <div class="header-home">
      <div class="logo">
        <img src="../assets/logo.png" alt="" />
      </div>
      <div class="fenlei" @click="fenlei">
        <img src="../assets/分类.png" alt="" />
      </div>
      <div class="header-top">
        <ul>
          <li>
            <router-link to="/" @click.native="cangeClass" exact
              >首页</router-link
            >
          </li>
          <li>
            <router-link to="/anli" @click.native="cangeClass" exact
              >案例</router-link
            >
          </li>
          <li>
            <router-link to="/fuwu" @click.native="cangeClass" exact
              >服务</router-link
            >
          </li>
          <li>
            <router-link to="/dingzhi" @click.native="cangeClass" exact
              >定制</router-link
            >
          </li>
        </ul>
      </div>
      <div class="header-call">服务热线：{{ xinxi.phone }}</div>
    </div>
  </div>
</template>

<script>
import { xinxiApi } from "@/api/home";
export default {
  name: "HomeView",
  components: {},
  data() {
    //这里存放数据
    return {
      xinxi: "",
      fenle: 0,
      isactive: 1,
    };
  },
  mounted() {
    xinxiApi().then((res) => {
      this.xinxi = res.data.data;
    });
  },
  methods: {
    fenlei() {
      this.fenle++;
      if (this.fenle == 1) {
        this.$refs.fenleis.style.height = 240 + "px";
      } else {
        this.$refs.fenleis.style.height = 80 + "px";
        this.fenle = 0;
      }
    },
    cangeClass() {
      this.$refs.fenleis.style.height = 80 + "px";
      this.fenle = 0;
    },
  },
};
</script>
<style>
@media (min-width: 769px) {
  .header-view {
    width: 100%;
    height: 80px;
  }
  .fenlei {
    display: none;
  }
  .header-home {
    width: calc(100% - 27.2%);
    height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
  }
  .logo {
    width: 117px;
    height: 59px;
  }
  .logo img {
    width: 100%;
    height: 100%;
  }
  .header-top {
    width: 30%;
    height: 80px;
  }
  .header-top a {
    color: #000;
  }
  .header-top ul {
    width: 100%;
    height: 100%;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-top ul li {
    float: left;
    width: 80px;
    text-align: center;
    line-height: 64px;
  }
  .router-link-active {
    border-bottom: 2px solid #006fff;
    padding-bottom: 5px;
    color: #006fff !important;
  }
  .header-call {
    width: 220px;
    height: 80px;
    font-size: 1vw;
    line-height: 80px;
  }
}
@media (max-width: 768px) {
  .header-view {
    width: 100%;
    height: 80px;
    position: relative;
    border-bottom: 2px solid #0ba1e4;
    overflow: hidden;
    transition: 0.8s;
  }
  .header-home {
    width: calc(100% - 3%);
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    width: 117px;
    height: 59px;
  }
  .logo img {
    width: 100%;
    height: 100%;
  }
  .header-top {
    position: absolute;
    top: 80px;
    z-index: 99;
    width: 100%;
  }
  .header-top ul {
    width: 100%;
  }
  .header-top ul li {
    width: 100%;
    padding: 10px;
    height: 40px;
    padding-right: 50px;
    text-align: right;
  }
  .header-top ul li a {
    color: #333;
  }
  .router-link-active {
    color: #0ba1e4 !important;
  }
  .header-call {
    display: none;
  }
  .fenlei {
    width: 30px;
    height: 30px;
    margin-right: 30px;
  }
  .fenlei img {
    width: 100%;
    height: 100%;
  }
}
</style>
