<template>
  <div class="home-hezuo">
    <div class="hezuo-zom">
      <div class="hezuo-title">合作生态</div>
      <div class="hezuo-bot">
        <div v-for="item in hezuo" :key="item.id">
          <div>
            <img :src="item.logo_image" alt="" />
            <p class="aurl">
              {{ item.name }}
            </p>
            <!-- <a :href="item.url" class="aurl">{{ item.name }}</a> -->
          </div>
        </div>
        <div v-for="item in loopCount" :key="item"></div>
        <!-- <div>
          <img src="" alt="" />
        </div>
        <div>
          <img src="" alt="" />
        </div>
        <div>
          <img src="" alt="" />
        </div>
        <div>
          <img src="" alt="" />
        </div>
        <div>
          <img src="" alt="" />
        </div>
        <div>
          <img src="" alt="" />
        </div>
        <div>
          <img src="" alt="" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { hezuoApi } from "@/api/home";
export default {
  components: {},
  data() {
    //这里存放数据
    return {
      hezuo: "",
      mingxun: "https://webapi.henanmingxun.com",
      // mingxun: "http://114.115.168.87:39888",
    };
  },
  computed: {
    loopCount() {
      const len = this.hezuo.length;
      if (len === 2 || len % 2 === 0) {
        return 2;
      } else if (len === 3 || len % 3 === 0) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  watch: {},
  mounted() {
    hezuoApi().then((res) => {
      res.data.data.data.map((item) => {
        item.logo_image = this.mingxun + item.logo_image;
      });
      this.hezuo = res.data.data.data;
    });
  },
  methods: {},
  beforeCreate() { },
  created() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
};
</script>
<style>
@media (min-width: 769px) {
  .home-hezuo {
    width: 100%;
    margin-bottom: 70px;
  }

  .hezuo-zom {
    width: calc(100% - 27.2%);
    margin: 0 auto;
  }

  .hezuo-title {
    height: 50px;
    font-size: 2.5vw;
    color: #333333;
    margin: 70px auto 60px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .hezuo-bot {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: flex-start;
    justify-content: space-between;
  }

  .hezuo-bot div {
    width: 300px;
    height: 163.5px;
    margin-bottom: 30px;
    /* margin-right: 20px; */
    position: relative;
  }

  .aurl {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 0;
  }

  .hezuo-bot div img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .home-hezuo {
    width: 100%;
    margin-bottom: 70px;
  }

  .hezuo-zom {
    width: calc(100% - 3%);
    margin: 0 auto;
  }

  .hezuo-title {
    height: 50px;
    font-size: 42px;
    color: #333333;
    margin: 70px auto 60px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .hezuo-bot {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
    justify-content: center;
  }

  .hezuo-bot div {
    width: 350px;
    height: 163.5px;
    margin-bottom: 30px;
    position: relative;
  }

  .aurl {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 0;
  }

  .hezuo-bot div img {
    width: 100%;
    height: 100%;
  }
}
</style>