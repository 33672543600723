<template>
  <div class="home-anli">
    <div class="anli-bane">
      <div class="anli-fen">
        <img src="../assets/分类.png" @click="drawer = true" alt="" />
        查看案例
      </div>
      <el-drawer
        title="项目开发案例"
        :visible.sync="drawer"
        :direction="direction"
      >
        <div class="anli-banetops">
          <ul>
            <li
              v-for="item in leixing"
              :key="item.id"
              :class="{ xiaxian: isActive === item.id }"
              @click="
                changeClass(item.id);
                xiaoshi((drawer = false));
              "
            >
              <img :src="item.image" alt="" />
              <p>{{ item.name }}</p>
            </li>
          </ul>
        </div>
      </el-drawer>
      <div class="anli-banetop">
        <ul>
          <!-- <li class="xiaxian">
            <img src="../assets/组 32.png" alt="" />
            <p>APP开发案例</p>
          </li>
          <li>
            <img src="../assets/组 32(1).png" alt="" />
            <p>小程序开发案例</p>
          </li>
          <li>
            <img src="../assets/组 32(2).png" alt="" />
            <p>公众号开发</p>
          </li>
          <li>
            <img src="../assets/组 32(3).png" alt="" />
            <p>管理系统案例</p>
          </li>
          <li>
            <img src="../assets/组 32(4).png" alt="" />
            <p>网站建设案例</p>
          </li> -->
          <li
            v-for="item in leixing"
            :key="item.id"
            :class="{ xiaxian: isActive === item.id }"
            @click="changeClass(item.id)"
          >
            <img :src="item.image" alt="" />
            <p>{{ item.name }}</p>
          </li>
        </ul>
      </div>
      <transition name="el-fade-in-linear">
        <div class="anli-bane-btom">
          <div
            v-for="(item, index) in duiying"
            :key="index"
          >
            <img :src="item.image" alt="" />
            <div class="anli-bei" @click="jump(index)"></div>
            <div class="anli-bei-tit" @click="jump(index)">
              点击前往相关案例
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { anliduiyingApi } from "@/api/home";
import { anlileixingApi } from "@/api/home";
export default {
  components: {},
  data() {
    //这里存放数据
    return {
      drawer: false,
      direction: "ltr",
      leixing: "",
      mingxun: "https://webapi.henanmingxun.com",
      // mingxun: "http://114.115.168.87:39888",
      duiying: "",
      isActive: 1,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    anlileixingApi().then((res) => {
      console.log(res);
      res.data.data.map((item) => {
        item.image = this.mingxun + item.image;
      });
      this.leixing = res.data.data;
    });
    let Params = {
      cate_id: 1,
    };
    anliduiyingApi(Params).then((res) => {
      console.log(res);
      res.data.data.data.map((item) => {
        item.image = this.mingxun + item.image;
      });
      this.duiying = res.data.data.data;
    });
  },
  methods: {
    // handleClose(done) {
    //   done();
    // },
    xiaoshi(done) {
      console.log(done);
      // done();
    },
    changeClass(index) {
      this.isActive = index;
      let Params = {
        cate_id: index,
      };
      anliduiyingApi(Params).then((res) => {
        console.log(res);
        res.data.data.data.map((item) => {
          item.image = this.mingxun + item.image;
        });
        this.duiying = res.data.data.data;
      });
    },
    jump(i) {
      window.open(this.duiying[i].url);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style>
@media (min-width: 769px) {
  .home-anli {
    width: 100%;
    margin-top: 20px;
  }
  .anli-fen {
    display: none;
  }
  .anli-bane {
    width: calc(100% - 27.2%);
    margin: 0 auto;
  }
  .anli-banetop {
    width: 100%;
    height: 160px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd;
  }
  .anli-banetop ul {
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .anli-banetop li {
    width: 166px;
    height: 100%;
    font-size: 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .anli-banetop li img {
    width: 5vw;
    height: 5vw;
    margin-bottom: 19px;
  }
  .xiaxian {
    border-bottom: 3px solid #0071ff;
  }
  .anli-bane-btom {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .anli-bane-btom div {
    width: 30%;
    background-color: #ddd;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px;
    margin-right: 1%;
    position: relative;
    z-index: 5;
    cursor: pointer;
  }
  .anli-bane-btom div .anli-bei {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 1s;
    z-index: 5;
  }
  .anli-bane-btom div .anli-bei-tit {
    width: 100%;
    height: 20px;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: 1s;
    opacity: 0;
    z-index: 6;
  }
  .anli-bane-btom div:hover .anli-bei {
    opacity: 1;
  }
  .anli-bane-btom div:hover .anli-bei-tit {
    top: 50%;
    margin-top: -10px;
    opacity: 1;
  }
  .anli-bane-btom div img {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 768px) {
  .anli-banetop {
    display: none;
  }
  .anli-banetops {
    width: 100%;
    height: 100px;
  }
  .anli-banetops li {
    padding-left: 20px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .anli-banetops li img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
  .el-drawer__container {
    top: 10% !important;
  }
  .el-drawer {
    width: 300px !important;
    height: 55% !important;
    border-radius: 10px !important;
  }
  .anli-bane-btom {
    width: 100%;
    display: flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .anli-bane-btom div {
    width: 300px;
    background-color: #ccc;
    margin-bottom: 30px;
    border-radius: 10px;
    position: relative;
  }
  .anli-bane-btom div img {
    width: 100%;
    height: 100%;
  }
  .anli-fen {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
  }
  .anli-fen img {
    width: 50px;
    height: 50px;
    margin-right: 30px;
  }
  .anli-bane-btom div .anli-bei {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 1s;
    z-index: 5;
  }
  .anli-bane-btom div .anli-bei-tit {
    width: 100%;
    height: 20px;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: 1s;
    opacity: 0;
    z-index: 6;
  }
  .anli-bane-btom div:hover .anli-bei {
    opacity: 1;
  }
  .anli-bane-btom div:hover .anli-bei-tit {
    top: 50%;
    margin-top: -10px;
    opacity: 1;
  }
}
</style>