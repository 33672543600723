<template>
  <div class="home-tuandui">
    <div class="tuandui-title">专业团队, 多对一模式</div>
    <ul class="tuandui-bom">
      <li>
        <div class="tuan-bei"></div>
        <img src="../assets/组 32(14).png" alt="" />
        <div class="tuan-title">量身定制</div>
        <div class="tuan-xian"></div>
        <div class="tuan-nei">根据客户个性化需求，量身 定制项目</div>
      </li>
      <li>
        <div class="tuan-bei"></div>
        <img src="../assets/组 32(15).png" alt="" />
        <div class="tuan-title">策划设计</div>
        <div class="tuan-xian"></div>
        <div class="tuan-nei">
          以策划为先导，对项目行业进行 整体调研，创意设计落地
        </div>
      </li>
      <li>
        <div class="tuan-bei"></div>
        <img src="../assets/组 33.png" alt="" />
        <div class="tuan-title">一站式服务</div>
        <div class="tuan-xian"></div>
        <div class="tuan-nei">
          从行业调研、策划、设计、开 发、运维全方位方案实施
        </div>
      </li>
      <li>
        <div class="tuan-bei"></div>
        <img src="../assets/组 33(1).png" alt="" />
        <div class="tuan-title">源码交付</div>
        <div class="tuan-xian"></div>
        <div class="tuan-nei">
          创作过程中的所有文档都会交付 客户，方便您二次开发
        </div>
      </li>
      <li>
        <div class="tuan-bei"></div>
        <img src="../assets/组 33(2).png" alt="" />
        <div class="tuan-title">迭代升级</div>
        <div class="tuan-xian"></div>
        <div class="tuan-nei">15年互联网研发经验，保证技术 快递迭代升级</div>
      </li>
      <li>
        <div class="tuan-bei"></div>
        <img src="../assets/组 33(3).png" alt="" />
        <div class="tuan-title">多对一服务</div>
        <div class="tuan-xian"></div>
        <div class="tuan-nei">从项目执行到后期交付，多个 部门同步服务您</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style>
@media (min-width: 769px) {
  .home-tuandui {
    width: 100%;
  }
  .tuandui-title {
    height: 50px;
    font-size: 2.5vw;
    color: #333333;
    margin: 70px auto 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tuandui-bom {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .tuandui-bom li {
    width: 16.7%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 5;
    cursor: pointer;
  }
  .tuandui-bom li:nth-child(1) {
    background: url("../assets/1\(1\).png");
  }
  .tuandui-bom li:nth-child(2) {
    background: url("../assets/1\(2\).png");
  }
  .tuandui-bom li:nth-child(3) {
    background: url("../assets/1\(3\).png");
  }
  .tuandui-bom li:nth-child(4) {
    background: url("../assets/1\(4\).png");
  }
  .tuandui-bom li:nth-child(5) {
    background: url("../assets/1\(5\).png");
  }
  .tuandui-bom li:nth-child(6) {
    background: url("../assets/1\(6\).png");
  }
  .tuan-bei {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .tuandui-bom li img {
    width: 120px;
    height: 120px;
    margin: 0px 0 33px;
    opacity: 0;
    transition: 1s;
  }
  .tuandui-bom li:hover img {
    opacity: 1;
    margin: 64px 0 33px;
  }
  .tuan-title {
    height: 30px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
  }
  .tuan-xian {
    width: 30px;
    border: 2px solid #fff;
    margin: 10px 0 20px;
  }
  .tuan-nei {
    width: 192px;
    height: 40px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    text-align: center;
    color: #ffffff;
  }
}
@media (max-width: 768px) {
  .home-tuandui {
    width: 100%;
  }
  .tuandui-title {
    height: 50px;
    font-size: 42px;
    color: #333333;
    margin: 70px auto 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tuandui-bom {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .tuandui-bom li {
    width: 45%;
    height: 400px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
    cursor: pointer;
  }
  .tuandui-bom li:nth-child(1) {
    background: url("../assets/1\(1\).png");
  }
  .tuandui-bom li:nth-child(2) {
    background: url("../assets/1\(2\).png");
  }
  .tuandui-bom li:nth-child(3) {
    background: url("../assets/1\(3\).png");
  }
  .tuandui-bom li:nth-child(4) {
    background: url("../assets/1\(4\).png");
  }
  .tuandui-bom li:nth-child(5) {
    background: url("../assets/1\(5\).png");
  }
  .tuandui-bom li:nth-child(6) {
    background: url("../assets/1\(6\).png");
  }
  .tuan-bei {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .tuandui-bom li img {
    width: 120px;
    height: 120px;
    margin: 0px 0 33px;
    opacity: 0;
    transition: 1s;
  }
  .tuandui-bom li:hover img {
    opacity: 1;
    margin: 64px 0 33px;
  }
  .tuan-title {
    height: 30px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
  }
  .tuan-xian {
    width: 30px;
    border: 2px solid #fff;
    margin: 10px 0 20px;
  }
  .tuan-nei {
    width: 192px;
    height: 40px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    text-align: center;
    color: #ffffff;
  }
}
</style>