<template>
  <div class='app'>
    <HeaderViewVue />
    <router-view />
    <FooterViewVue />
  </div>
</template>

<script>
import FooterViewVue from './views/FooterView.vue'
import HeaderViewVue from './views/HeaderView.vue'
export default {
  components: { HeaderViewVue, FooterViewVue },
}
</script>
<style>
body {
  padding-right: 0 !important
}
</style>