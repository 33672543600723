<template>
  <div class="home-view">
    <div class="banner">
      <el-carousel>
        <el-carousel-item v-for="(item, index) in banner" :key="index">
          <img :src="item.image" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home-jyan">
      <ul class="jyan-pan">
        <li>
          <div class="pan-shu">
            <span>15</span>
            <span>年</span>
          </div>
          <div class="pan-xian"></div>
          <p>研发经验</p>
        </li>
        <li>
          <div class="pan-shu">
            <span>100</span>
            <span>+</span>
          </div>
          <div class="pan-xian"></div>
          <p>研发人员</p>
        </li>
        <li>
          <div class="pan-shu">
            <span>24H</span>
            <span>*7</span>
          </div>
          <div class="pan-xian"></div>
          <p>优质服务</p>
        </li>
        <li>
          <div class="pan-shu">
            <span>500</span>
            <span>+</span>
          </div>
          <div class="pan-xian"></div>
          <p>优质客户</p>
        </li>
        <li>
          <div class="pan-shu">
            <span>3889</span>
            <span>套</span>
          </div>
          <div class="pan-xian"></div>
          <p>成功案例</p>
        </li>
      </ul>
    </div>
    <!-- 优势 -->
    <div class="home-youshi">
      <div class="youshi-main">
        <div class="youshi-title">选择我们的优势</div>
        <div class="youshi-bot">
          <ul class="youshi-ul">
            <li v-for="item in youshi" :key="item.id">
              <img :src="item.image" alt="" />
              <div>{{ item.yitle }}</div>
              <p>{{ item.description }}</p>
            </li>
            <!-- <li>
              <img src="../assets/技术.png" alt="" class="img-l" />
              <div>技术双引擎</div>
              <p>采用.NET/JAVA技术</p>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <homeAnli />
    <homeLiucheng />
    <homeTuandui />
    <homeHzuo />
  </div>
</template>

<script>
import { lunboApi } from "@/api/home.js";
import { youshiApi } from "@/api/home.js";
import homeTuandui from "@/components/homeTuandui.vue";
import homeAnli from "@/components/homeAnli.vue";
import homeHzuo from "@/components/homeHezuo.vue";
import homeLiucheng from "@/components/homeLiucheng.vue";
export default {
  components: { homeAnli, homeTuandui, homeHzuo, homeLiucheng },
  data() {
    //这里存放数据
    return {
      banner: [],
      youshi: "",
      mingxun: "https://webapi.henanmingxun.com",
      // mingxun: "http://114.115.168.87:39888",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    lunboApi().then((res) => {
      res.data.data.map((item) => {
        item.image = this.mingxun + item.image;
      });
      this.banner = res.data.data;
    });
    youshiApi().then((res) => {
      res.data.data.map((item) => {
        item.image = this.mingxun + item.image;
      });
      this.youshi = res.data.data;
    });
  },
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.home-view {
  width: 100%;
  position: relative;
}
.home-jyan {
  width: calc(100% - 27.2%);
  margin: 0 auto;
}
.jyan-pan {
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 14px;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.jyan-pan li {
  width: 200px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  float: left;
  align-items: center;
}
.pan-shu span:nth-child(1) {
  font-size: 32px;
  font-weight: 500;
}
.pan-shu span:nth-child(2) {
  font-size: 14px;
}
.pan-xian {
  border: 1px solid #006fff;
  width: 24px;
  height: 2px;
  margin: 5px 0;
  animation-name: myanimate;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes myanimate {
  0% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(-20px);
  }
}
@media (max-width: 768px) {
  .banner {
  width: 100%;
}
.banner img {
  width: 100%;
  height: 100%;
}
.el-carousel__container {
  height: 150px !important;
}

  .home-youshi {
    width: 100%;
    background-color: #f3f4f7;
    padding: 72px 50px;
  }
  .youshi-main {
    width: calc(100% - 3%);
    height: 100%;
    margin: 0 auto;
  }
  .youshi-title {
    height: 50px;
    font-size: 42px;
    color: #333333;
    margin: 70px auto 60px;
    text-align: center;
  }
  .youshi-bot {
    width: 100%;
    margin-top: 52px;
  }
  .youshi-ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .youshi-ul li {
    width: 242px;
    height: 100%;
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding: 42px 37px 30px 37px;
    background-color: #fff;
  }
  .youshi-ul li img {
    width: 120px;
    height: 128px;
  }
  .img-l {
    width: 160px !important;
  }
  .youshi-ul li div {
    font-size: 20px;
    font-weight: bold;
    margin: 8px 0 14px;
  }
  .youshi-ul li p {
    width: 100%;
    height: 16px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #999999;
  }
}
@media (min-width: 769px) {
  .banner {
    width: 100%;
  }
  .banner img {
    width: 100%;
    height: 100%;
  }
  .el-carousel__container {
    height: 360px !important;
  }

  .home-youshi {
    width: 100%;
    background-color: #f3f4f7;
    padding: 72px 50px;
  }
  .youshi-main {
    width: calc(100% - 27.2%);
    height: 100%;
    margin: 0 auto;
  }
  .youshi-title {
    height: 50px;
    font-size: 2.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .youshi-bot {
    width: 100%;
    margin-top: 52px;
  }
  .youshi-ul {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: center;
  }
  .youshi-ul li {
    width: 50%;
    height: 100%;
    text-align: center;
    border-right: 1px solid #ddd;
    padding: 42px 37px 30px 37px;
    background-color: #fff;
  }
  .img-l {
    width: 160px !important;
  }
  .youshi-ul li div {
    font-size: 20px;
    font-weight: bold;
    margin: 8px 0 14px;
  }
  .youshi-ul li p {
    width: 100%;
    height: 16px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #999999;
  }
}
</style>