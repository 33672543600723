<template>
  <div class="home-liucheng">
    <div class="liucheng-zom">
      <div class="liucheng-title">合作流程</div>
      <div class="liucheng-bot">
        <div class="liucheng-lef">
          <div>
            <img src="../assets/组 32(6).png" alt="" />
            <p>需求分析</p>
            <span>01</span>
          </div>
          <div>
            <img src="../assets/组 32(7).png" alt="" />
            <p>原生框架</p>
            <span>03</span>
          </div>
          <div>
            <img src="../assets/组 32(8).png" alt="" />
            <p>程序开发</p>
            <span>05</span>
          </div>
          <div>
            <img src="../assets/组 32(9).png" alt="" />
            <p>验收上线</p>
            <span>07</span>
          </div>
        </div>
        <div class="liucheng-lef">
          <div>
            <img src="../assets/组 32(10).png" alt="" />
            <p>项目管理</p>
            <span>02</span>
          </div>
          <div>
            <img src="../assets/组 32(11).png" alt="" />
            <p>创意设计</p>
            <span>04</span>
          </div>
          <div>
            <img src="../assets/组 32(12).png" alt="" />
            <p>产品测试</p>
            <span>06</span>
          </div>
          <div>
            <img src="../assets/组 32(13).png" alt="" />
            <p>运营维护</p>
            <span>08</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style>
@media (min-width: 769px) {
  .home-tuandui {
    width: 100%;
  }
  .liucheng-zom {
    width: calc(100% - 27.2%);
    margin: 0 auto;
  }
  .liucheng-title {
    width: 600px;
    height: 50px;
    font-size: 42px;
    color: #333333;
    margin: 70px auto 60px;
    text-align: center;
  }
  .liucheng-bot {
    width: 100%;
  }
  .liucheng-lef {
    width: 100%;
    height: 190px;
    display: flex;
    margin-bottom: 55px;
  }
  .liucheng-lef div {
    width: 295px;
    height: 190px;
    background: url("../assets/组\ 32\(5\).png");
    position: relative;
  }
  .liucheng-lef div img {
    width: 77px;
    height: 77px;
    position: absolute;
    top: 20%;
    left: 20%;
  }
  .liucheng-lef div p {
    width: 100px;
    position: absolute;
    top: 65%;
    left: 22%;
  }
  .liucheng-lef div span {
    width: 23px;
    height: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    position: absolute;
    top: 47%;
    right: 17%;
  }
  .liucheng-lef:nth-child(2) {
    width: 100%;
    height: 190px;
    display: flex;
    margin-bottom: 55px;
    justify-content: flex-end;
  }
}
@media (max-width: 768px) {
  .home-tuandui {
    width: 100%;
  }
  .liucheng-zom {
    width: calc(100% - 3%);
    margin: 0 auto;
  }
  .liucheng-title {
    height: 50px;
    font-size: 42px;
    color: #333333;
    margin: 70px auto 60px;
    text-align: center;
  }
  .liucheng-bot {
    display: flex;
    justify-content: space-between;
  }
  .liucheng-lef {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .liucheng-lef div{
    margin-bottom: 20px;
  }
  .liucheng-lef span{
    display: none;
  }
}
</style>