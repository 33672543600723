import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './common/css.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
// import 'swiper/dist/css/swiper.css'
// import Swiper from "swiper"
// Vue.use(Swiper);
import Mint from 'mint-ui';
Vue.use(Mint);
import { Swipe, SwipeItem } from 'mint-ui';

Vue.component(Swipe.name, Swipe);
Vue.component(SwipeItem.name, SwipeItem);

Vue.prototype.axios = axios;
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
