import service from "@/util/request";


export const lunboApi = (data) => {
    return service({
        method: 'POST',
        url: '/a/banner',
        data: data
    })
}
export const xinxiApi = (data) => {
    return service({
        method: 'POST',
        url: '/a/basic',
        data: data
    })
}
export const youshiApi = (data) => {
    return service({
        method: 'POST',
        url: '/a/advantage',
        data: data
    })
}
export const anlileixingApi = (data) => {
    return service({
        method: 'POST',
        url: '/a/anli_cate_list',
        data: data
    })
}
export const anliduiyingApi = (data) => {
    return service({
        method: 'POST',
        url: '/a/anli_list',
        data: data
    })
}
export const hezuoApi = (data) => {
    return service({
        method: 'POST',
        url: '/a/partner',
        data: data
    })
}
export const zixunApi = (data) => {
    return service({
        method: 'POST',
        url: '/a/zx',
        data: data
    })
}
export const wenzhangApi = (data) => {
    return service({
        method: 'GET',
        url: '/a/article',
        data: data
    })
}