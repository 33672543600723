<template>
  <div class="footer-view">
    <div class="footer-main">
      <div class="footer-rong">
        <!-- <div class="footer-logo">
          <img src="../assets/logo(1).png" alt="" />
          <div class="logo-title">
            <span>电话咨询：</span>
            <p>{{ xinxi.phone }}</p>
          </div>
        </div> -->
        <!-- <div class="footer-pan">
          <ul class="panpd">
            <li class="panpd-zon">
              <div class="panpd-title">案例</div>
              <ul class="panpd-nei">
                <li>客户案例</li>
              </ul>
            </li>
            <li class="panpd-zon">
              <div class="panpd-title">解决方案</div>
              <ul class="panpd-nei">
                <li>商城类解决方案</li>
                <li>代驾解决方案</li>
                <li>加征解决方案</li>
                <li>教育解决方案</li>
                <li>更多</li>
              </ul>
            </li>
            <li class="panpd-zon">
              <div class="panpd-title">关于我们</div>
              <ul class="panpd-nei">
                <li>公司简化</li>
                <li>企业文化</li>
                <li>荣誉资质</li>
                <li>新闻观点</li>
              </ul>
            </li>
            <li class="panpd-zon">
              <div class="panpd-title">合作加盟</div>
              <ul class="panpd-nei">
                <li>联系我们</li>
                <li>加入我们</li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="footer-wein">
          <img src="" alt="" />
          <p>欢迎扫码关注咨询</p>
        </div> -->
      </div>
      <div class="footer-bott">
        <span
          >Copyright © 河南铭迅网络科技有限公司 Henan Mingxun Network Technology
          Co., Ltd &nbsp; <a @click="jump">{{ xinxi.beian }}</a></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { xinxiApi } from "@/api/home";
export default {
  components: {},
  data() {
    //这里存放数据
    return {
      xinxi: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    xinxiApi().then((res) => {
      this.xinxi = res.data.data;
    });
  },
  methods: {
    jump() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style>
@media (min-width: 769px) {
  .footer-view {
    width: 100%;
    background-color: #343434;
    color: #fff;
  }
  .footer-main {
    margin: 0 auto;
    padding-bottom: 42px;
    width: calc(100% - 27.2%);
  }
  .footer-rong {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .footer-logo {
    width: 200px;
  }
  .footer-logo img {
    width: 117px;
    height: 45px;
    margin-bottom: 33px;
  }
  .logo-title {
    width: 100%;
    height: 53px;
  }
  .logo-title span {
    font-size: 12px;
  }
  .logo-title p {
    margin-top: 20px;
  }
  .footer-pan {
    height: 100%;
    margin-top: 20px;
  }
  .panpd-title {
    margin-bottom: 30px;
  }
  .panpd {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center;
  }
  .panpd li {
    width: 180px;
    margin-top: 30px;
  }
  .panpd-nei {
    font-size: 14px;
    color: #ccc;
  }
  .panpd-nei li {
    margin-top: 5px;
  }

  .footer-wein {
    width: 150px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .footer-wein img {
    width: 88px;
    height: 88px;
    margin-bottom: 30px;
  }

  .footer-bott {
    font-size: 12px;
    color: #ccc;
    text-align: center;
    margin-top: 50px;
  }
  .footer-bott a {
    color: #ccc;
  }
}
@media (max-width: 768px) {
  .footer-view {
    width: 100%;
    background-color: #343434;
    color: #ccc;
  }
  .footer-main {
    margin: 0 auto;
    padding-bottom: 42px;
    width: calc(100% - 3%);
  }
  .footer-rong {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  .footer-logo img {
    width: 117px;
    height: 45px;
    margin-bottom: 33px;
  }
  .footer-pan {
    display: none;
  }
  .logo-title {
    width: 150px;
    text-align: center;
  }
  .logo-title P {
    margin-top: 20px;
    font-size: 20px;
  }
  .footer-bott {
    font-size: 12px;
    margin-top: 50px;
    text-align: center;
  }
  .footer-bott a {
    color: #ccc;
  }
  .footer-wein {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
  }
  .footer-wein img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  .footer-wein p {
    font-size: 14px;
  }
}
</style>